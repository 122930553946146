<template>
  <div class="login-page">
    <div class="login">
      <div class="login-logo"></div>

      <div class="login-box">
        <h1>注册</h1>
        <div class="login-info">
          <van-field
            v-model="name"
            type="text"
            :rules="[{ required: true, message: '' }]"
            placeholder="请输入姓名"
          >
          </van-field>

          <van-field
            v-model.number="userName"
            type="number"
            maxlength="11"
            placeholder="请输入手机号"
          >
          </van-field>
          <van-field
            v-model="userPwd"
            type="text"
            placeholder="请输入验证码"
            maxlength="6"
          >
           
            <template #button>
              <van-button
                size="small"
                color="#e95944"
                round
                type="info"
                :class="[showTime ? 'time' : '', 'btn-w']"
                @click="getCodeFn"
                >{{
                  showTime
                    ? timeNumber + "s"
                    : getCode
                    ? "重新获取"
                    : "发送验证码"
                }}</van-button
              >
            </template>
            
          </van-field>


          <van-field
            v-model="email"
            type="text"
            :rules="[{ required: true, message: '' }]"
            placeholder="请输入邮箱"
            
          >

          </van-field>

          <van-field
            v-model="cardid"
            type="text"
            :rules="[{ required: true, message: '' }]"
            placeholder="请输入身份证号"
            
          >
            
          </van-field>

          <van-field
            v-model="password"
            type="password"
            :rules="[{ required: true, message: '' }]"
            placeholder="请输入密码"
            
          >
            
          </van-field>


          <van-field
            v-model="passwordre"
            type="password"
            :rules="[{ required: true, message: '' }]"
            placeholder="请确认密码"
          >
          </van-field>

          <p class="footer-links">
            <router-link style="margin-right:10px" to="/login"
            >登录</router-link>
            <router-link to="/resetPassword"
            >找回密码</router-link>
          </p>
        
          <van-button
            class="login-btn"
            :disabled="!(userName && userPwd && cardid && password && passwordre)"
            round
            color="#e95944"
            type="info"
            block
            @click="toLogin"
            >注册</van-button
          >
        </div>
       </div>

       <div class="login-footer"></div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
export default {
  data() {
    return {
      name:'',
      userName: "",
      userPwd:"",
      email:'',
      cardid:'',
      password: "",
      passwordre:"",
      showTime: false,
      timeNumber: 0,
      getCode: false,
      timeInterval: null,
    };
  },
  mounted() {},
  methods: {
    clickLeftFn() {
      window.close();
    },
    formatterFn(val, n) {
      const v = val.replace(/\D/g, "");
      if (n === "userPwd") {
        this.userPwd = v;
        return this.userPwd;
      }
      this.userName = v;
      return this.userName;
    },
    getCodeFn() {
      if (this.showTime) {
        return;
      }
      if (this.timeInterval) {
        clearInterval(this.timeInterval);
      }
      const reg = /^1\d{10}$/;
      if (!reg.test(this.userName)) {
        this.$toast("请输入正确的手机号码！");
        return;
      }
      let that = this;
      this.getCode = true;
      this.showTime = true;
      this.timeNumber = 60;
      this.timeInterval = setInterval(() => {
        if (that.timeNumber < 2) {
          that.showTime = false;
          return;
        }
        that.timeNumber--;
      }, 1000);

      const data = new FormData();
      data.append("mobile", this.userName);

      this.$api.SYS_VALID_REG({ mobile: this.userName }).then(() => {
      });
    },
    checkFn() {
      const reg = /^1\d{10}$/,
        regCode = /^\d{6}$/;
      if (!reg.test(this.userName) || !regCode.test(this.userPwd)) {
        this.$toast("请输入正确的手机号码和验证码！");
        return false;
      }

      

      if (!this.email) {
        this.$toast("请输入邮箱！");
        return false;
      }

      if (!this.cardid) {
        this.$toast("请输入身份证号！");
        return false;
      }

      if (this.password.length < 6) {
        this.$toast("密码不能少于6位");
        return false;
      }

      if(this.password !== this.passwordre){
        this.$toast("两次密码不一致！");
        return false;
      }
      return true;
    },
    toLogin() {
      const n = this.checkFn();
      if (!n) {
        return;
      }
      this.$toast.loading({
        type: "loading",
        message: "注册中...",
        loadingType: "spinner",
        duration: 0,
      });
      const data = {
        username:this.name,
        mobile: this.userName,
        captcha: this.userPwd,
        email:this.email,
        cardid:this.cardid,
        password:md5(this.password)
      };
      this.$api.SYS_USER_REG(data).then(() => {
        this.$toast.clear();
        this.$toast('注册成功,请登录')
        this.$router.replace("/login");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-links{
  text-align:right;
  a{
    color:#e95944;
  }
}
.login-page {
  background:url(../../assets/images/common/login-m1.png);
  background-size:100% 100%;
  min-height:100vh;
  .login {
    height: 100%;
    padding-top:10px;
    position: relative;
   
    .login-logo{
      width:320px;
      height:125px;
      margin:0 auto;
      margin-top:20px;
      background:url(../../assets/images/common/login-m2.png);
      background-size:100% auto;
      background-repeat:no-repeat;
      h1{
        font-size:24px;
      }
    }
      
    ::v-deep {
      .van-cell {
        background-color: transparent;
        margin-top: 30px;
        padding: 0;
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #f1f4f6;
      }
      .van-field__control {
        font-size: 16px;
        color: #333;
      }
      .van-field__right-icon {
        display: flex;
      }
      .van-cell::after {
        display: none;
      }
    }
    .title-word {
      .top {
        font-size: 28px;
      }
      .welcome {
        font-size: 14px;
        color: #666666;
        margin-top: 10px;
      }
    }
    &-info {
      // padding: 0 33px;
      // margin-top: -50px;
      .btn-w {
        min-width: 86px;
        cursor: pointer;
      }
      .time {
        // border-color: #999 !important;
        // background: #999 !important;
        background: #e95944 !important;
        // border-radius: 17px;
        opacity: 0.5;
      }

      .phone {
        width: 13px;
        height: 17px;
        margin-right: 15px;
        margin-top: 10px;
      }
      .pwd {
        width: 17px;
        height: 16px;
        margin-right: 12px;
        margin-top: 5px;
      }
      .right-icon {
        width: 34px;
        height: 34px;
      }
      .login-btn {
        height: 50px;
        margin-top: 40px;
      }
      .register {
        font-size: 14px;
        color: #666666;
        text-align: right;
        margin-top: 15px;
      }
      .pact {
        display: flex;
        margin-top: 18px;
        .van-checkbox {
          // align-self: flex-start;
          margin-right: 10px;
          min-width: 20px;
        }
        .pact-text {
          font-size: 12px;
          a {
            color: #e95944;
          }
        }
      }
    }
  }
  .login-box{
      max-width:270px;
      background:#fff;
      margin:0px auto;
      border-radius:20px;
      padding:10px 20px;
      box-shadow:5px 5px 20px #efefef;
      position: relative;
      z-index:999;
      h1{
        font-size:24px;
      }
    }
    .login-footer{
      width:100%;
      height:165px;
      margin:0 auto;
      margin-top:40px;
      background:url(../../assets/images/common/login-m3.png);
      background-size:100% auto;
      background-repeat:no-repeat;
      position: fixed;
      bottom:0;
      left:50%;
      margin-left:-50%;
    }
}
@media (min-width: 992px) {
  .login-page {
    background:url(../../assets/images/common/login-pc1.png);
    background-size:100% 100%;
    min-height:100vh;
    padding-bottom:100px;
    .login {
      height: 100%;
      padding-top:10px;
      position: relative;
      .login-logo{
        width:650px;
        height:225px;
        margin:0 auto;
        margin-top:40px;
        background:url(../../assets/images/common/login-pc2.png);
        background-size:100% auto;
        background-repeat:no-repeat;
      }
      
      ::v-deep {
        .van-cell {
          background-color: transparent;
          margin-top: 30px;
          padding: 0;
          display: flex;
          align-items: center;
          height: 50px;
          border-bottom: 1px solid #f1f4f6;
        }
        .van-field__control {
          font-size: 16px;
          color: #333;
        }
        .van-field__right-icon {
          display: flex;
        }
        .van-cell::after {
          display: none;
        }
      }
      .title-word {
        .top {
          font-size: 28px;
        }
        .welcome {
          font-size: 14px;
          color: #666666;
          margin-top: 10px;
        }
      }
      &-info {
        .btn-w {
          min-width: 86px;
          cursor: pointer;
        }
        .time {
          // border-color: #999 !important;
          // background: #999 !important;
          background: #e95944 !important;
          // border-radius: 17px;
          opacity: 0.5;
        }

        .phone {
          width: 13px;
          height: 17px;
          margin-right: 15px;
          margin-top: 10px;
        }
        .pwd {
          width: 17px;
          height: 16px;
          margin-right: 12px;
          margin-top: 5px;
        }
        .right-icon {
          width: 34px;
          height: 34px;
        }
        .login-btn {
          height: 50px;
          margin-top: 40px;
        }
        .register {
          font-size: 14px;
          color: #666666;
          text-align: right;
          margin-top: 15px;
        }
        .pact {
          display: flex;
          margin-top: 18px;
          .van-checkbox {
            margin-right: 10px;
            min-width: 20px;
          }
          .pact-text {
            font-size: 12px;
            a {
              color: #e95944;
            }
          }
        }
      }
      .login-info{
        width:460px;
        margin:20px auto;
      }
    }
    .login-box{
      max-width:460px;
      background:#fff;
      margin:0px auto;
      border-radius:20px;
      padding:10px 20px;
      box-shadow:5px 5px 20px #efefef;
      position: relative;
      z-index:999;
      h1{
        font-size:24px;
      }
    }
    .login-footer{
      width:600px;
      height:215px;
      margin:0 auto;
      margin-top:40px;
      background:url(../../assets/images/common/login-pc3.png);
      background-size:100% auto;
      background-repeat:no-repeat;
      position: fixed;
      bottom:0;
      left:50%;
      margin-left:-300px;

    }
  }
}
</style>
